import React from 'react'
import { Link } from 'gatsby'
import { navigate } from 'gatsby'
import Layout from '../components/Layout'
import { useForm } from 'react-hook-form'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    fetch('/.netlify/functions/sendgrid', {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        navigate('/contact-complete')
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Layout>
      <div className="inner">
        <div className="common-header">
          <h1>お問い合わせ</h1>
          <p className="subtitle">Contact</p>
        </div>
        {/* /.common-header */}

        <p>
          ご質問のある方は、こちらのメールフォームよりお気軽にご相談ください。
        </p>

        <form
          id="contact"
          method="post"
          name="contact"
          data-netlify="true"
          onSubmit={handleSubmit(onSubmit)}
        >
          <p>
            会社名<span className="require"></span>
            {errors.companyName && errors.companyName.type === 'required' && (
              <p style={{ color: 'red' }}>必須です。</p>
            )}
          </p>
          <input
            type={'text'}
            name={'companyName'}
            placeholder="例）株式会アルゴノート"
            {...register('companyName', { required: true })}
          />

          <p>部署名</p>
          <input
            type={'text'}
            name={'department'}
            placeholder="例）営業第二課"
            {...register('department', { required: false })}
          />

          <p>
            担当者名<span className="require"></span>
            {errors.name && errors.name.type === 'required' && (
              <p style={{ color: 'red' }}>必須です。</p>
            )}
          </p>
          <input
            type={'text'}
            name={'name'}
            className="short"
            placeholder="例）山田 太郎"
            {...register('name', { required: true })}
          />

          <p>
            電話番号<span className="require"></span>
            {errors.tel && errors.tel.type === 'required' && (
              <p style={{ color: 'red' }}>必須です。</p>
            )}
          </p>
          <input
            type={'tel'}
            name={'tel'}
            className="short"
            placeholder="例）03-1234-5678"
            {...register('tel', { required: true })}
          />

          <p>
            メールアドレス<span className="require"></span>
            {errors.email && errors.email.type === 'required' && (
              <p style={{ color: 'red' }}>必須です。</p>
            )}
          </p>
          <input
            type={'email'}
            name={'email'}
            placeholder="例）example@algonaut.jp"
            {...register('email', { required: true })}
          />

          <p>
            お問い合わせ内容<span className="require"></span>
            {errors.message && errors.message.type === 'required' && (
              <p style={{ color: 'red' }}>必須です。</p>
            )}
          </p>
          <textarea
            name={'message'}
            cols="30"
            rows="10"
            className="short"
            placeholder="お問い合わせ内容をできるだけ具体的にお書きください。"
            {...register('message', { required: true })}
          ></textarea>

          <div className="contact-btn">
            <input type="submit" value="送信する" />
          </div>
          {/* /.contact-btn */}
        </form>

        <p className="note">
          ご入力していただきました個人情報については、ご本人の同意がない限り、お問い合わせに回答する目的以外に利用することはございません。詳しくは本サイトの「
          <Link to={'/policy/'}>情報セキュリティ方針</Link>
          」をご確認ください。
        </p>
        <p className="common-margin l"></p>
        <p className="common-margin l"></p>
      </div>
      {/* /.inner */}
    </Layout>
  )
}

export default Contact
